import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import isEmpty from 'lodash.isempty';
import { ReCaptcha } from 'react-recaptcha-v3';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { validateData } from '../../actions/validatorActions';
import {
  getUserTenants,
  loginUser,
  logOutUser,
  registerUser,
} from '../../actions/authActions';
import { CheckBox, RadioInput, TextInput } from '../common/forms';
import Button from '../common/button';
import { FullLoader } from '../common/loader';

function LoginForm(props) {
  const {
    returnTo = '',
    redirect,
    showLeadContent = true,
    showRegisterForm,
    animate,
    onDone = () => {},
    updateStore = true,
  } = props;
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const errors = useSelector((state) => state.validator.errors);
  const isValid = useSelector((state) => state.validator.isValid);
  const isFetching = useSelector((state) => state.http.isFetching);
  const [recaptchatoken, setRecaptchatoken] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [rules, setRules] = useState({
    email: {
      required: true,
      type: 'email',
    },
    password: {
      required: true,
    },
    tenant: {
      required: false,
    },
  });

  useEffect(() => {
    dispatch(validateData(user, rules));
  }, [rules]);

  useEffect(() => {
    if (!isEmpty(authUser)) {
      const userData = { ...user };
      userData.email = authUser.email;
      setUser({ ...userData });
    }
  }, [authUser]);

  const handleChange = (event) => {
    const userData = { ...user };

    userData[event.target.name] = event.target.checked || event.target.value;

    setUser({ ...userData });

    dispatch(validateData(userData, rules));
  };

  const handleTenantChange = (event) => {
    const userData = { ...user };

    userData.tenant = event.target.value;

    setUser({ ...userData });

    dispatch(validateData(userData, rules));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(validateData(user, rules, true));

    if (isEmpty(errors)) {
      if (!isEmpty(tenants) || (authUser && !isEmpty(authUser.tenant))) {
        login();
      } else {
        dispatch(getUserTenants({ ...user })).then((resp) => {
          if (resp && !isEmpty(resp.data)) {
            const newrules = { ...rules };
            newrules.tenant = { required: true };
            setRules({ ...newrules });

            setTenants(resp.data);
          } else {
            login();
          }
        });
      }
    }
  };

  const login = () =>
    dispatch(
      loginUser(
        { ...user, 'g-recaptcha-response': recaptchatoken },
        returnTo,
        redirect,
        updateStore
      )
    ).then((resp) => {
      if (resp) {
        onDone();
      }
    });

  const verifyCallback = (recaptchaToken) => {
    setRecaptchatoken(recaptchaToken);
  };

  const logout = () => {
    dispatch(logOutUser());
  };

  return (
    <div
      id="login-form"
      className={`w-full relative ${animate && 'animated fadeIn'}`}
    >
      {isFetching && <FullLoader fixed={false} />}
      <form
        className="w-full bg-white rounded md:rounded-none md:rounded-tr md:rounded-br px-4 md:px-8 py-4 md:pt-6 md:pb-8"
        onSubmit={handleSubmit}
      >
        {showLeadContent && (
          <div className="mb-5">
            {isEmpty(authUser) && (
              <h1 className="text-xl font-semibold text-blue">
                Just a moment!
              </h1>
            )}
            {!isEmpty(authUser) && (
              <h1 className="text-xl font-semibold text-blue">
                Just a moment, {authUser.name}
                ...
              </h1>
            )}
            {isEmpty(tenants) && isEmpty(authUser) && (
              <p className="text-sm font-medium text-gray-500 mt-1">
                Please login or create an account to continue...
              </p>
            )}
            {(!isEmpty(authUser) || !isEmpty(tenants)) && (
              <p className="text-sm font-medium text-gray-500 mt-1">
                Please login to continue...
              </p>
            )}
          </div>
        )}
        <div className="mb-4">
          <TextInput
            type="text"
            name="email"
            label="Email"
            placeholder="name@example.com"
            onChange={handleChange}
            error={errors.email}
            value={user.email}
          />
        </div>
        <div className="mb-4">
          <TextInput
            type="password"
            label="Password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            error={errors.password}
            icon
          />
        </div>
        {!isEmpty(tenants) && (
          <div className="mb-4" id="tenants-radio-group">
            <RadioInput
              name="tenant"
              design="bold"
              label="Your email is linked to several companies. Please select a company to login"
              options={tenants.map((t) => ({
                label: t.display_name,
                value: t.tid,
              }))}
              onChange={handleTenantChange}
              flexDirection="column"
              error={errors.tenant}
              selected={user.tenant}
            />
          </div>
        )}
        <div>
          <ReCaptcha
            sitekey="6LdFrPkUAAAAAMfzDSPuO67JSWtY34CgE94Mfdhj"
            verifyCallback={verifyCallback}
          />
        </div>
        <div className="flex items-center justify-between">
          <Button
            type="submit"
            design="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Login
          </Button>
        </div>
        <div className="text-center  mt-3">
          <div className="flex justify-center">
            {!isEmpty(authUser) && (
              <div>
                <Button onClick={logout} design="plain" className="underline">
                  Not {authUser.name}?
                </Button>
              </div>
            )}
            <Button href="/forgot-password" design="plain">
              Forgot Password?
            </Button>
          </div>
          {isEmpty(authUser) && (
            <p className="text-base text-gray-500 mt-2">
              Don&apos;t have an account?
              {showRegisterForm && (
                <a
                  id="signup-form-btn"
                  className="underline text-sm font-medium text-gray-500 hover:text-blue-dark mt-2 ml-2"
                  onClick={showRegisterForm}
                >
                  Signup Now!
                </a>
              )}
              {!showRegisterForm && (
                <Link
                  href={`/register?returnTo=${returnTo}`}
                  id="signup-link-btn"
                  className="underline text-sm font-medium text-gray-500 hover:text-blue-dark mt-2 ml-2"
                  onClick={showRegisterForm}>
                  
                    Signup Now!
                  
                </Link>
              )}
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

LoginForm.propTypes = {
  returnTo: PropTypes.string,
  redirect: PropTypes.bool,
  showLeadContent: PropTypes.bool,
  showRegisterForm: PropTypes.bool,
  animate: PropTypes.bool,
  onDone: PropTypes.func,
};

function RegisterForm(props) {
  const {
    showLoginForm,
    animate,
    redirect,
    onDone = () => {},
    invitation = false,
    forcedType,
    registerLink,
    tid = null,
  } = props;

  const router = useRouter();
  const dispatch = useDispatch();

  const errors = useSelector((state) => state.validator.errors);
  const isValid = useSelector((state) => state.validator.isValid);
  const isFetching = useSelector((state) => state.http.isFetching);

  const [user, setUser] = useState({});
  const [recaptchatoken, setRecaptchatoken] = useState(null);
  const [redirectTo, setRedirectTo] = useState(
    router.query.returnTo !== undefined ? router.query.returnTo : ''
  );

  const type = forcedType || router.query.type;

  const [rules, setRules] = useState({
    name: {
      required: true,
    },
    email: {
      required: true,
      type: 'email',
    },
    password: {
      required: true,
    },
    type: {
      required: true,
    },
    terms: {
      required: true,
    },
  });

  useEffect(() => {
    const userData = { ...user };

    if (type && (type === 'individual' || type === 'company')) {
      userData.type = type;
    } else {
      userData.type = 'individual';
    }

    setUser({ ...userData });
  }, []);

  useEffect(() => {
    if (user.type === 'company') {
      setRules({
        ...rules,
        company_name: {
          required: true,
        },
      });
    } else {
      setRules({
        ...rules,
        company_name: {
          required: false,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (router.pathname === '/job/apply/[id]') {
      setUser({ ...user, type: 'individual' });
      setRedirectTo(window.location.href);
    }
    if (router.query.subscribe_modal === '1') {
      setUser({ ...user, type: 'individual' });
    }
  }, [router.pathname]);

  const canShowType = () =>
    !invitation &&
    router.pathname !== '/job/apply/[id]' &&
    router.query.subscribe_modal !== '1';

  const handleChange = (event) => {
    const userData = { ...user };

    userData[event.target.name] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    dispatch(validateData(userData, rules));

    setUser(userData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(validateData(user, rules));

    if (isEmpty(errors) && isValid) {
      dispatch(
        registerUser(
          {
            ...user,
            'g-recaptcha-response': recaptchatoken,
          },
          redirect,
          registerLink,
          redirectTo,
          invitation,
          tid
        )
      ).then(() => onDone());
    }
  };

  const verifyCallback = (recaptchaToken) => {
    setRecaptchatoken(recaptchaToken);
  };
  return (
    <div
      id="register-form"
      className={`w-full relative ${animate && 'animated fadeIn'}`}
    >
      {isFetching && <FullLoader fixed={false} />}
      <form
        className="w-full bg-white rounded md:rounded-none md:rounded-tr md:rounded-br px-4 md:px-8 py-4 md:pt-6 md:pb-8"
        onSubmit={handleSubmit}
      >
        {invitation && (
          <div className="mb-5">
            <h1 className="text-xl font-semibold text-blue">
              Congratulations on the invitation!
            </h1>
            {user.type === 'individual' && (
              <p className="text-sm font-medium text-gray-500 mt-1">
                Set up your account to get started.
              </p>
            )}
          </div>
        )}
        {!invitation && (
          <div className="mb-5">
            <h1 className="text-xl font-semibold text-blue">
              Get Started Today!
            </h1>
            {user.type === 'individual' && (
              <p className="text-sm font-medium text-gray-500 mt-1">
                Join the leading job board for the modern workforce.
              </p>
            )}
            {user.type === 'company' && (
              <p className="text-sm font-medium text-gray-500 mt-1">
                Take the hustle out of your hiring and HR processes.
              </p>
            )}
          </div>
        )}
        {canShowType() && (
          <div className="mb-4">
            <RadioInput
              name="type"
              design="bold"
              label="Are you a company or an individual?"
              options={[
                { label: 'Individual', value: 'individual' },
                { label: 'Company', value: 'company' },
              ]}
              onChange={handleChange}
              flexDirection="row"
              error={errors.type}
              selected={user.type}
            />
          </div>
        )}
        {user.type === 'company' && (
          <div className="mb-4">
            <TextInput
              type="text"
              name="company_name"
              label="Company Name"
              placeholder="Your Company Name (Elevolt Limited)"
              onChange={handleChange}
              error={errors.name}
            />
          </div>
        )}
        <div className="mb-4">
          <TextInput
            type="text"
            name="name"
            label="Full Name"
            placeholder="Your Full Name (John Doe)"
            onChange={handleChange}
            error={errors.name}
          />
        </div>
        <div className="mb-4">
          <TextInput
            type="text"
            name="email"
            label={
              user.type === 'individual' ? 'Your Email' : 'Your Work Email'
            }
            placeholder={
              user.type === 'individual'
                ? 'name@example.com'
                : 'name@yourcompany.com'
            }
            onChange={handleChange}
            error={errors.email}
          />
        </div>
        <div className="mb-4">
          <TextInput
            type="password"
            label="Password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            error={errors.password}
            icon
          />
        </div>
        <div className="mb-4">
          <CheckBox
            name="terms"
            onChange={handleChange}
            label={
              <span className="text-sm m-0 leading-none">
                By clicking Register below you agree to our{' '}
                <a href="/terms">terms & conditions</a>
              </span>
            }
            error={errors.terms}
          />
        </div>
        <div>
          <ReCaptcha
            sitekey="6LdFrPkUAAAAAMfzDSPuO67JSWtY34CgE94Mfdhj"
            verifyCallback={verifyCallback}
          />
        </div>
        <div className="flex items-center justify-between">
          <Button fullWidth disabled={!isValid} type="submit">
            {invitation ? 'Accept Invitation' : 'Register'}
          </Button>
        </div>
        {!invitation && (
          <div className="text-center">
            <p className="text-sm text-gray-500 mt-1">
              Already have an account?{' '}
              {showLoginForm && (
                <a
                  className="underline text-sm font-medium hover:text-blue-dark"
                  onClick={showLoginForm}
                >
                  Login
                </a>
              )}
              {!showLoginForm && (
                <Link
                  href={`/login?returnTo=${redirectTo}`}
                  className="underline text-sm font-medium hover:text-blue-dark"
                  onClick={showLoginForm}>
                  
                    Login
                  
                </Link>
              )}
            </p>
          </div>
        )}
      </form>
    </div>
  );
}

RegisterForm.propTypes = {
  showLoginForm: PropTypes.string,
  redirect: PropTypes.bool,
  animate: PropTypes.bool,
  onDone: PropTypes.func,
  forcedType: PropTypes.string,
  registerLink: PropTypes.string,
  invitation: PropTypes.string,
  tid: PropTypes.string,
};

function AuthViewsWrapper(props) {
  const {
    form = 'login',
    showLeadContent,
    animate = false,
    redirect,
    onDone = () => {},
    updateStore = true,
  } = props;
  const authUser = useSelector((state) => state.auth.user);
  const [showForm, setShowForm] = useState(form);

  const dispatch = useDispatch();

  const showLoginForm = () => {
    setShowForm('login');
  };

  const showRegisterForm = () => {
    setShowForm('register');

    if (!isEmpty(authUser)) {
      dispatch(logOutUser());
    }
  };

  return (
    <>
      {showForm === 'login' && (
        <LoginForm
          showLeadContent={showLeadContent}
          showRegisterForm={showRegisterForm}
          animate={animate}
          redirect={redirect}
          onDone={onDone}
          updateStore={updateStore}
        />
      )}
      {showForm === 'register' && (
        <RegisterForm
          showLoginForm={showLoginForm}
          animate={animate}
          redirect={redirect}
          onDone={onDone}
        />
      )}
    </>
  );
}

AuthViewsWrapper.propTypes = {
  form: PropTypes.string,
  showLeadContent: PropTypes.bool,
  animate: PropTypes.bool,
  redirect: PropTypes.bool,
  onDone: PropTypes.func,
};

export { LoginForm, RegisterForm };

export default AuthViewsWrapper;
